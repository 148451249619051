import { required } from '@vuelidate/validators';
import CreateValidationMixin from '@/mixins/validation';

function getValidations(self) {
  const data = {
    orgId: {
      required: {
        rule: required,
        message: () => self.$t('organizationRequired'),
      },
    },
    accNum: {
      required: {
        rule: required,
        message: () => self.$t('accountRequired'),
      },
    },
  };

  return {
    formData: data,
  };
}

export default CreateValidationMixin((self) => getValidations(self));
