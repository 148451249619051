<script>
import api from '@/libs/api';
import * as storage from '@/libs/storage';
import AlertModal from '#c/uikit/AlertModal/AlertModal.vue';
import AddCounterForm from '#c/add-counter-form/AddCounterForm.vue';
import Spinner from '#c/uikit/Spinner.vue';

export default {
  name: 'AddView',

  data() {
    return {
      storage,

      task: {
        submitting: false,
        error: null,
      },

      modal: {
        visible: false,
        type: '',
        title: '',
        description: '',
        buttonText: '',
        closeHandler: () => {},
      },
    };
  },

  computed: {
    orgsLoaded() {
      return this.$store.getters.loaded_orgs;
    },
    organizations() {
      return this.$store.state.organizations;
    },
  },

  watch: {
    // route-guard
    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        if (!route.query.code) {
          this.$router.replace({
            name: 'home',
          });
        }
      },
    },
  },

  methods: {
    // https://gitlab.abr-region.ru/ukps/uni-paypage-proxy/-/merge_requests/35
    async handleSubmit(formData) {
      const { code: hashCode } = this.$route.query;
      if (!hashCode) return;

      try {
        this.task.submitting = true;

        // Используется прямой вызов метода `api.proxy`,
        // потому что дефолтный метод создания тасков
        // с использованием ключа маппера (api.createTask('MAPPER_KEY'))
        // не дает возможности передавать динамические аргументы
        const response = await api.proxy({
          method: 'post',
          endpoint: `/qrInfo/update/${hashCode}`,
        }, {
          organization_code: formData.org,
          account: formData.acc,
          connected: true,
        });

        const {
          connected,
          organization_code,
          account,
        } = response.data.qrInfo;

        if (connected) {
          this.showModal({
            type: 'positive',
            title: 'Поздравляем!',
            description: 'Счетчик успешно подключен',
            buttonText: 'Закрыть',
            closeHandler: () => {
              this.$router.replace({
                name: 'review',
                query: {
                  org: organization_code,
                  acc: account,
                },
              });
            },
          });
        }
      } catch (error) {
        this.showModal({
          type: 'negative',
          title: 'Ошибка',
          description: error?.message || 'Ошибка получения информации',
          buttonText: 'Закрыть',
          closeHandler: () => {
            this.hideModal();

            // // https://gitlab.abr-region.ru/ukps/uni-paypage-proxy/-/merge_requests/35
            // if (error?.status === 400) {
            //   this.$router.replace({
            //     name: 'home',
            //   });
            // }
          },
        });
      } finally {
        this.task.submitting = false;
      }
    },

    showModal(options) {
      this.modal.visible = true;
      this.modal.type = options.type;
      this.modal.title = options.title;
      this.modal.description = options.description;
      this.modal.buttonText = options.buttonText;
      this.modal.closeHandler = options.closeHandler;
    },

    hideModal() {
      this.modal.visible = false;
      this.modal.type = null;
      this.modal.title = null;
      this.modal.description = null;
      this.modal.buttonText = null;
      this.modal.closeHandler = null;
    },
  },

  components: {
    Spinner,
    AlertModal,
    AddCounterForm,
  },
};
</script>

<template>
  <div class="connect-view">
    <h1>{{ $t('connectCounter') }}</h1>

    <template v-if="!orgsLoaded">
      <div class="preloader">
        <Spinner color="base" />
        <span>{{ $t('dataLoading') }}</span>
      </div>
    </template>
    <template v-else>
      <AddCounterForm
        @submit="handleSubmit"
      />
    </template>

    <AlertModal
      v-if="modal.visible"
      :type="modal.type"
      :title="modal.title"
      :description="modal.description"
      :button-text="modal.buttonText"
      @close="modal.closeHandler"
    />
  </div>
</template>

<style lang="stylus" scoped>
  @import '~#a/style/config'

  h1 {
    margin-bottom: 32px;

    @media (max-width: 767px) {
      margin-bottom: (@margin-bottom / 1.5);
    }
  }

  .preloader {
    font-size: 30px;
    text-align: center;
    padding: 4em 0;
    color: $color-base;

    @media(max-width: 767px) {
      font-size: 20px;
    }

    .spinner {
      font-size: 1.3em;
      margin-right: 0.3em;
    }

    span {
      vertical-align: top;
      padding-top: 0.15em;
      display: inline-block;
    }
  }
</style>
