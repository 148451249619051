<script>
import Select from '#form/Select.vue';
import Input from '#form/Input.vue';
import Button from '#c/uikit/Button.vue';
import validation from './validation';

export default {
  name: 'AddCounterForm',

  emits: ['submit'],

  mixins: [validation],

  data() {
    return {
      formData: {
        orgId: null,
        accNum: null,
      },
    };
  },

  computed: {
    organizationsByRegion() {
      return this.$store.state.organizationsByRegion;
    },

    regions() {
      return this.$store.state.regions;
    },

    organizations() {
      return this.$store.state.organizations;
    },

    organizationsMap() {
      return new Map(this.organizations.map((org) => [org.id, org]));
    },

    regionsMap() {
      return new Map(this.regions.map((region) => [region.id, region]));
    },
  },

  methods: {
    getRegionName(organization) {
      const found_region = this.regionsMap.get(organization.region_id);
      return found_region ? `${found_region.name} : ` : '';
    },
    onSubmit() {
      const { orgId, accNum } = this.formData;
      const organization = this.organizationsMap.get(orgId);
      const orgCode = organization?.code;

      if (!orgCode || !accNum) {
        return;
      }

      this.$emit('submit', {
        org: orgCode,
        acc: accNum,
      });
    },
  },

  components: {
    Select,
    Input,
    Button,
  },
};
</script>

<template>
  <div class="reg-counter">
    <form
      @submit.prevent="onSubmit"
      class="reg-counter__form"
    >
      <div class="reg-counter__group">
        <div class="reg-counter__col">
          <Select
            v-model="formData.orgId"
            :label="$t('organizationLabel')"
            :placeholder="$t('regionPlaceholder')"
            :options="organizationsByRegion"
            :get-group-value="getRegionName"
          />
        </div>
        <div class="reg-counter__col">
          <p class="description">
            {{ $t('organizationSelectorNote') }}
          </p>
        </div>
      </div>

      <div class="reg-counter__group">
        <div class="reg-counter__col">
          <Input
            v-model="formData.accNum" :validation-state="$v.formData.accNum" :validation-messages="validationMessages.accNum"
            :label="$t('accountLabel')"
            placeholder="10000000"
            clearable="true"
          />
        </div>
        <div class="reg-counter__col">
          <p class="description">
            {{ $t('accountSelectorNote') }}
          </p>
        </div>
      </div>

      <div class="reg-counter__group">
        <Button type="submit">
          {{ $t('connectCounter') }}
        </Button>
      </div>
    </form>
  </div>
</template>

<style lang="stylus" scoped>
  @import '~#a/style/config'

  $columns-gap = 70px;
  $desktop = 768px;

  .system-message {
    margin-bottom: 32px;
  }

  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    @media(max-width: 767px) {
      flex-direction: row;
      margin-bottom: 42px;
    }
  }

  .col {
    width: 100%;
    padding: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    @media(min-width: $desktop) {
      width: 50%;
      padding: 0 ($columns-gap / 2);
    }
  }

  .description {
    color: $color-label;
    line-height: 1.7;

    @media(max-width: 767px) {
      font-size: 14px;
      line-height: 1.3;
      margin-top: 8px;
      margin-bottom: 16px;
      color: $color-note;
      background-color: rgba(@color, .15);
      border-radius: 4px;
      padding: 8px;
    }
  }

  .account-note {
    position: relative;

    i {
      color: rgba($color-label, .1);
      font-size: 320px;
      position: absolute;
      width: @font-size;
      height: @font-size;
      right: 0;
      left: 0;
      margin: auto;
      top: -40px;

      @media(max-width: 767px) {
        display: none;
      }
    }
  }

  .reg-counter {
    .btn {
      backface-visibility: hidden;

      @media(max-width: 767px) {
        display: block;
        width: 100%;
        position: sticky;
        bottom: 10px;
        z-index: 10;
      }
    }
  }

  .reg-counter__form {
    & > .reg-counter__group ~ .reg-counter__group {
      margin-top: 20px;

      @media(min-width: $desktop) {
        margin-top: 40px;
      }
    }
  }

  .reg-counter__group {
    display: flex;
    flex-direction: column;

    @media(min-width: $desktop) {
      flex-direction: row;

      & > * ~ * {
        margin-left: $columns-gap;
      }

      .reg-counter__col {
        flex: 1;
      }
    }
  }
</style>
